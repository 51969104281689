<template>
  <li class="menu-item">
    <span class="form-label mr-auto">
      <i class="mr-3" :class="'i-' + icon"></i> {{ placeholder }}</span
    >

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="ios_tex"
      :download="ios_tex"
      :href="ios_tex"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="ios_model"
      :download="ios_model"
      :href="ios_model"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="sfb"
      :download="sfb"
      :href="sfb"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="sfb_tex"
      :download="sfb_tex"
      :href="sfb_tex"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="web"
      target="_blank"
      :href="
        `https://webar.gora.studio/?modif=${modif.idt_model_modif}&mask=${web}`
      "
    >
      <i class="i-eye float-left md-down:d-none"></i>
      <i class="i-eye lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_prev") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="web"
      :download="web"
      :href="web"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <mega-button
      class="btn-sm bg-white md-down:p-0"
      @click="$refs['file'].click()"
    >
      <i class="i-upload float-left md-down:d-none"></i>
      <i class="i-upload lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_upl") }}</span>
    </mega-button>

    <input
      class="input-file"
      hidden
      type="file"
      ref="file"
      :accept="accept"
      @change="addFiles"
    />
  </li>
</template>

<script>
export default {
  name: "ModelNewPage",
  props: ["type", "placeholder", "modif", "mask", "accept"],
  data() {
    return {
      file: undefined,
      mask_ios: this.mask["ios"] || null,
      mask_android: this.mask["android"] || null,
      android_sfb: null,
      android_tex: null,
      mask_web: this.mask["web"] || null
    };
  },
  computed: {
    zip() {
      return this.type === "apple" && this.mask_ios
        ? this.mask_ios["zip"]["url"]
        : "";
    },
    sfb() {
      return this.type === "android_sfb" &&
        this.mask_android &&
        this.android_sfb
        ? this.android_sfb["url"]
        : "";
    },
    sfb_tex() {
      return this.type === "android_tex" &&
        this.mask_android &&
        this.android_tex
        ? this.android_tex["url"]
        : "";
    },
    web() {
      return this.type === "web" && this.mask_web
        ? this.mask_web["zip"]["url"]
        : "";
    },
    icon() {
      switch (this.type) {
        case "apple":
          return "apple";
          break;
        case "android_sfb":
        case "android_tex":
          return "android";
          break;
        case "web":
          return "globe";
          break;
      }
    }
  },
  mounted() {
    this.eventListener(
      this.$el,
      "drag dragstart dragend dragover dragenter dragleave drop",
      e => {
        e.preventDefault();
        e.stopPropagation();
      }
    );

    this.android_sfb = this.mask_android ? this.mask_android["sfb"] : null;
    this.android_tex = this.mask_android ? this.mask_android["texture"] : null;

    this.eventListener(this.$el, "dragover dragenter", () => this.dragEnter());
    this.eventListener(this.$el, "dragleave dragend drop", () =>
      this.dragEnd()
    );
    this.eventListener(this.$el, "drop", e => this.dragDropped(e));
  },
  methods: {
    target() {
      this.$refs["file"].click();
    },

    addFiles(event) {
      let files = event.target.files;

      if (files) this.file = files[0];

      this.$emit("input", this.file);

      let formData = new FormData();
      formData.append("idt_model_modif", this.modif["idt_model_modif"]);

      if (this.type === "android_sfb")
        formData.append("mask_android_sfb", this.file);

      if (this.type === "android_tex")
        formData.append("mask_android_texture", this.file);

      if (this.type === "apple") formData.append("mask_ios_zip", this.file);

      if (this.type === "web") formData.append("mask_web_zip", this.file);

      if (this.type === "music") formData.append("audio", this.file);

      this.$api.v2
        .put("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          event.target.value = "";
          this.file = undefined;
          this.$emit("drop");
          this.$alert.success("File was updated");
        })
        .catch(error => console.log(error));
    },

    addEvent(obj, type, fn) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, false);
      } else if (obj.attachEvent) {
        obj.attachEvent("on" + type, function() {
          return fn.call(obj, window.event);
        });
      }
    },

    eventListener(el, events, listener) {
      events = events.split(" ");

      events.map(event => {
        this.addEvent(el, event, listener);
      });
    },

    dragEnter() {
      this.$el.classList.add("drag-hover");
    },

    dragEnd() {
      this.$el.classList.remove("drag-hover");
    },

    dragDropped(e) {
      if (e.dataTransfer.items) this.file = e.dataTransfer.items[0].getAsFile();

      this.$emit("input", this.file);
      this.$emit("drop");
    }
  }
};
</script>
